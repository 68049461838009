import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getRtwTaskDefinition,
  isEligibleToDisplayGratitudeComponent,
  isUkRtwNoCaasIntegration,
} from "../helpers/configHelper";
import { isDragonstone, isSalesforceApplication } from "../helpers/url-params";
import { useGetAppConfigQuery } from "../reduxStore/api/apiSlice";
import { useAppDispatch } from "../reduxStore/reduxHooks";
import { setConfigData, setFeatureFlag } from "../reduxStore/slices/configSlice";
import { addPageLoadMetric } from "../utility/adobe-analytics";
import { getASHConfigurations, isCustomerService } from "../utility/app-config-helper";
import { JobDetailsModel } from "../utility/application-data";
import { dispatchOnDev } from "../utility/dev-env-helpers";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../utility/enums/common";
import { getCountryForDomain } from "../utility/locale-helper";
import { useApplicationState } from "./useApplicationState.hook";
import { GratitudeStateEnum, GratitudeStateType, useGratitudeComponentState } from "./useGratitudeComponentState.hook";
import { useGetJobDetails } from "./apis/useGetJobDetails";
import { useGetCaasRTWStatus } from "./apis/useGetCaasRTWStatus";
import { useGetApplicationManageData } from "./apis/useGetApplicationManageData";
import { useGetNHEAppointment } from "./apis/useGetNHEAppointment";
import { useGetRTWAppointment } from "./apis/useGetRTWAppointment";
import { useGetCandidateData } from "./apis/useGetCandidateData";
import { useGetCandidateAtoZWithoutPassword } from "./apis/useGetCandidateAtoZWithoutPassword";
import { useGetCandidateAtoZWithPassword } from "./apis/useGetCandidateAtoZWithPassword";
import { useGetOnbaseDocumentStatus } from "./apis/useGetOnbaseDocumentStatus";
import { useGetDrugTestStatus } from "./apis/useGetDrugTestStatus";
import { useGetAllContigenciesData } from "./apis/useGetAllContigenciesData";
import { useGetBackgroundCheckStatus } from "./apis/useGetBackgroundCheckStatus";
import { useGetMedicalCheckStatus } from "./apis/useGetMedicalCheckStatus";
import { useGetCaasI9Status } from "./apis/useGetCaasI9Status";
import { useGetDTAppointment } from "./apis/useGetDTAppointment";

export const useConfig = () => {
  const [appConfigLoaded, setAppConfigLoaded] = useState(false);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [isErrorLoadingConfig, setIsErrorLoadingConfig] = useState(false);
  const dispatch = useAppDispatch();
  const { applicationId, requisitionId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();

  // Call API at initial render, to avoid unnecessary UI re-render.
  // The following useGetRTWAppointment, candidateAtoZ, candidateAtoZWithPassword, dtResponse are probably called for the same purpose.
  // We're not ready for refactor. They can be write into this section once we have enough test coverage for refactor.
  useGetDTAppointment();

  const appConfig = useGetAppConfigQuery();
  const candidateData = useGetCandidateData();
  const caasRTW = useGetCaasRTWStatus();
  const scheduleDetails = useGetJobDetails();
  const applicationManageData = useGetApplicationManageData();
  const nheAppointment = useGetNHEAppointment();
  const rtwAppointment = useGetRTWAppointment();
  const applicationState = useApplicationState();
  const candidateAtoZ = useGetCandidateAtoZWithoutPassword();
  const candidateAtoZWithPassword = useGetCandidateAtoZWithPassword();
  const gratitudeComponentState = useGratitudeComponentState(applicationState, nheAppointment, scheduleDetails);
  const allContingencies = useGetAllContigenciesData();
  const { airBgcStatus: airBgcResponse, bgcStatus: bgcResponse } = useGetBackgroundCheckStatus();
  const dtResponse = useGetDrugTestStatus();
  const onbaseResponse = useGetOnbaseDocumentStatus();
  const mcResponse = useGetMedicalCheckStatus();
  const caasI9Response = useGetCaasI9Status();

  const isAppConfigLoaded =
    !appConfig.isLoading &&
    applicationManageData &&
    candidateData &&
    allContingencies &&
    caasRTW && // initial state for the value is null.
    scheduleDetails &&
    // bgcResponse &&
    mcResponse &&
    // airBgcResponse &&
    onbaseResponse &&
    caasI9Response;

  const getGratitudeComponentUIState = (gratitudeComponentState: GratitudeStateType) => {
    const country = getCountryForDomain();

    dispatch(
      setFeatureFlag({
        key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_TO_SHOW_GRATITUDE_COMPONENT,
        value: isEligibleToDisplayGratitudeComponent(country, gratitudeComponentState, applicationId),
      })
    );

    dispatch(
      setConfigData({
        key: CONFIG_DATA_KEY.GRATITUDE_COMPONENT_STATE,
        value: gratitudeComponentState as GratitudeStateEnum,
      })
    );
  };

  const getIsSalesforceApplication = () => {
    if (isSalesforceApplication(applicationId)) {
      dispatch(
        setFeatureFlag({
          key: CONFIG_FEATURE_FLAG.IS_SALESFORCE_APPLICATION,
          value: isSalesforceApplication(applicationId),
        })
      );
    }
  };

  const getIsUkRtwNoCaasIntegration = () => {
    const rtwTaskDefinition = getRtwTaskDefinition(applicationId);

    dispatch(
      setConfigData({
        key: CONFIG_DATA_KEY.RTW_TASK_DEFINITION,
        value: rtwTaskDefinition,
      })
    );

    dispatch(
      setFeatureFlag({
        key: CONFIG_FEATURE_FLAG.IS_UK_RTW_NO_CAAS_INTEGRATION,
        value: isUkRtwNoCaasIntegration(applicationId),
      })
    );
  };

  const getIsCustomerServiceJob = () => {
    dispatch(
      setFeatureFlag({
        key: CONFIG_FEATURE_FLAG.IS_CUSTOMER_SERVICE_JOB,
        value: isCustomerService(applicationId),
      })
    );
  };

  const checkForError = () => {
    if (appConfig.isError) {
      dispatchOnDev(() => console.error("error loading appConfig: ", appConfig.error));
      setIsErrorLoadingConfig(true);
    }
  };

  const setupASHConfigs = async (scheduleDetails: JobDetailsModel, appConfig: any) => {
    if (!appConfigLoaded) {
      const configResponse = await getASHConfigurations(applicationId, scheduleDetails, appConfig);

      if (configResponse?.successfullyLoaded) {
        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_ASH_CONFIG_LOADED,
            value: configResponse.successfullyLoaded,
          })
        );
        setAppConfigLoaded(configResponse.successfullyLoaded);
      }
    }
  };

  useEffect(() => {
    if (appConfigLoaded && isAppConfigLoaded) {
      getIsCustomerServiceJob();
      getIsUkRtwNoCaasIntegration();
      getIsSalesforceApplication();
      setIsConfigLoaded(true);
    }
  }, [isAppConfigLoaded, appConfigLoaded]);

  useEffect(() => {
    getGratitudeComponentUIState(gratitudeComponentState);
  }, [isAppConfigLoaded, gratitudeComponentState, appConfigLoaded]);

  // Calling ASH configuration from AppConfig
  useEffect(() => {
    if (
      !appConfigLoaded &&
      applicationId &&
      requisitionId &&
      scheduleDetails &&
      appConfig.data &&
      candidateData &&
      applicationManageData
    ) {
      setupASHConfigs(scheduleDetails, appConfig.data);
      // TODO: Refactor global variable to use redux instead
      window.ASH_CHECKLIST_isChecklistReferral = true;
      addPageLoadMetric(candidateData, applicationManageData, scheduleDetails, window, applicationId, "Ash thank you", {
        jobId: requisitionId,
        isDragonstone: isDragonstone(requisitionId),
      });
    }
  }, [
    appConfigLoaded,
    applicationId,
    requisitionId,
    appConfig,
    scheduleDetails,
    appConfig.data,
    applicationManageData,
    candidateData,
    scheduleDetails,
  ]);

  checkForError();

  return { isConfigLoaded, isErrorLoadingConfig, appConfigLoaded };
};
