import { Button, ButtonSize } from "@amzn/stencil-react-components/button";
import { Flyout, FlyoutContent, FlyoutTitleRenderer } from "@amzn/stencil-react-components/flyout";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import React, { useCallback, useState } from "react";
import ICalendarLink from "react-icalendar-link";
import { useParams } from "react-router-dom";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import { Stage } from "../../helpers/get-settings";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useChecklistArb } from "../../hooks/use-arb";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { getProcessedJCAddress, isVirtualJob } from "../../utility/jc-response-helper";
import AppLoader from "../common/AppLoader";
import { GoogleMapLinkWithDirectionButton } from "../google-map-link";
import { H2 } from "../typography";
import { FirstDayDetails } from "./first-day-details";
import { FirstDayHeader } from "./first-day-header";
import { ScreeningResultsProgressControlled } from "./screening-result-progress";
import { ShiftNoAvailableHeader } from "./shift-unavailable";
import { AppointmentDetailsFlyout } from "./appointment-details";
import { AppointmentDetailsV2Header } from "./appointment-header";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { DTAppointmentHeader } from "./DTAppointmentHeader";
import { DTSchedulingHeader } from "./DTSchedulingHeader";

export enum NHEAppointmentStatusEnum {
  COMPLETED = "COMPLETED",
  RESERVED = "RESERVED",
  UNKNOWN = "UNKNOWN",
  ON_HOLD = "ON_HOLD",
}
interface AppointmentCardControlledProps {
  displayNHE?: boolean;
  displayDT: boolean;
  isDTNeedToBeScheduled: boolean;
  scheduleIdFound?: boolean;
  displayScreening?: boolean;
  stage?: Stage;
  cssURLWithoutScheduleId?: string;
  loading?: boolean;
  error?: boolean;
  onViewNHEDetailsClick: () => void;
  onViewStartDateDetailsClick: () => void;
}

const titleRenderer: FlyoutTitleRenderer = ({ titleText }) => (
  <H2 id="modal-title" color={"#FFFFFF"}>
    {titleText}
  </H2>
);

export const AppointmentCardControlled = ({
  displayNHE,
  displayDT,
  isDTNeedToBeScheduled,
  scheduleIdFound,
  cssURLWithoutScheduleId,
  loading,
  error,
  displayScreening,
  stage,
  onViewNHEDetailsClick,
  onViewStartDateDetailsClick,
}: AppointmentCardControlledProps) => {
  const { isEligibleForCss } = useAppSelector(getFeatureFlag);
  const { sdaData } = useAppSelector(getConfigData);
  const { applicationId } = useParams<{ applicationId: string }>();
  const jcDetails = useGetJobDetails();

  const isSalesforce = isSalesforceApplication(applicationId);

  const bundle = useChecklistArb();

  const [isStartDateDetailsViewOpen, setIsStartDateDetailsViewOpen] = useState(false);
  const [isPreHireDetailsViewOpen, setIsPreHireDetailsViewOpen] = useState(false);
  const [onclickEvent, setOnclickEvent] = useState<string>("");

  const openStartDateDetailsView = useCallback(() => {
    setIsStartDateDetailsViewOpen(true);
    onViewStartDateDetailsClick();
  }, [onViewStartDateDetailsClick]);

  const closeStartDateDetailsView = useCallback(() => {
    setIsStartDateDetailsViewOpen(false);
  }, []);

  const openPreHireDetailsView = useCallback(
    (event) => {
      setIsPreHireDetailsViewOpen(true);
      setOnclickEvent(event);
      onViewNHEDetailsClick();
    },
    [onViewNHEDetailsClick]
  );

  const closePreHireDetailsView = useCallback(() => {
    setIsPreHireDetailsViewOpen(false);
  }, []);

  if (!sdaData || !jcDetails) return <AppLoader />;

  const loadingText = bundle.getMessage("checklist-General-TaskStatus-Loading");

  const addToCalendar = bundle.formatMessage("Checklist-AppointmentCard-ButtonText3");

  if (loading) {
    return (
      <Col alignItems="center" padding={"2rem"} backgroundColor={HVH_COLORS.NEUTRAL_0}>
        <Spinner size={SpinnerSize.Small} loadingText={loadingText} showText={true} />
      </Col>
    );
  }

  const detailsTitle = bundle.getMessage("Checklist-CardControlled-DetailsTitle");

  const startDayOnSiteTitle = bundle.getMessage("Checklist-CardControlled-FirstDayTitle");

  if (!scheduleIdFound) {
    if (isEligibleForCss) {
      return <ShiftNoAvailableHeader cssURLWithoutScheduleId={cssURLWithoutScheduleId} />;
    } else if (!isSalesforce) {
      return null;
    }
  }

  if (error || (!isSalesforce && (!sdaData || !jcDetails)) || (isSalesforce && !jcDetails)) {
    return null; // Remove and customize the error state if needed
  }

  const startDateTime = jcDetails?.firstDayOnSiteL10N + " " + jcDetails?.startTimeL10N;
  const vh = window.innerHeight + "px";

  const address = jcDetails && getProcessedJCAddress(jcDetails);
  const isVirtual = isVirtualJob(jcDetails);
  const startDayCalendarEvent = {
    title: startDayOnSiteTitle,
    startTime: startDateTime,
    location: address,
  };

  const addToCalendarKeyDown = (event: any, classname: string) => {
    if (event.key === " " || event.code === "Space") {
      if (isDevEnv()) console.log("called addToCalendarKeyDown");
      event.preventDefault();
      (document.querySelector(classname) as any).click();
    }
  };

  if (displayNHE && !displayScreening) {
    return (
      <>
        <AppointmentDetailsV2Header
          sfRequiredLanguage={isSalesforce ? jcDetails?.requiredLanguageFromSalesForce : undefined}
          stage={stage}
          onViewDetailsClick={(event) => {
            openPreHireDetailsView(event);
          }}
        />
        <AppointmentDetailsFlyout
          isOpen={isPreHireDetailsViewOpen}
          closeFlyout={closePreHireDetailsView}
          appointmentType={onclickEvent === "RTW" ? NHE_EVENT_TYPE.RTW : NHE_EVENT_TYPE.NHE}
        />
      </>
    );
  } else if (displayDT && isDTNeedToBeScheduled) {
    return <DTSchedulingHeader />;
  } else if (displayDT && !isDTNeedToBeScheduled) {
    return (
      <DTAppointmentHeader
        sfRequiredLanguage={isSalesforce ? jcDetails?.requiredLanguageFromSalesForce : undefined}
        stage={stage}
      />
    );
  } else if (!displayNHE && displayScreening) {
    return <ScreeningResultsProgressControlled jobDetails={jcDetails} />;
  } else if (!displayNHE && jcDetails && !displayScreening) {
    return (
      <>
        <FirstDayHeader
          jobDetails={jcDetails}
          onViewDetailsClick={openStartDateDetailsView}
          cssURLWithoutScheduleId={cssURLWithoutScheduleId}
        />
        <Flyout
          data-testid={"first-day-details-flyout"}
          isOpen={isStartDateDetailsViewOpen}
          close={closeStartDateDetailsView}
          aria-labelledby={"modal-title"}
        >
          <div style={{ height: vh }}>
            <FlyoutContent
              titleText={detailsTitle}
              renderTitle={titleRenderer}
              buttons={[
                <Row key="details-buttons" width="100%" justifyContent="space-around" alignItems="center">
                  <Row gridGap="1rem">
                    <Button
                      data-testid="start-add-to-calendar-start-day"
                      as={"Col"}
                      size={ButtonSize.Small}
                      onKeyDown={(event) => addToCalendarKeyDown(event, ".add-to-calendar-start-day")}
                      tabIndex={-1}
                    >
                      <ICalendarLink
                        key="calendar-button"
                        filename={startDayOnSiteTitle}
                        event={startDayCalendarEvent}
                        className={".add-to-calendar-start-day"}
                      >
                        {addToCalendar}
                      </ICalendarLink>
                    </Button>
                    <GoogleMapLinkWithDirectionButton
                      isVirtual={isVirtual}
                      address={address}
                      idAddress={jcDetails.address}
                    />
                  </Row>
                </Row>,
              ]}
              onCloseButtonClick={closeStartDateDetailsView}
            >
              {jcDetails && (
                <FirstDayDetails
                  startDate={jcDetails.firstDayOnSite}
                  address={address}
                  sdaDetails={sdaData}
                  isVirtual={isVirtual}
                  cssURLWithoutScheduleId={cssURLWithoutScheduleId}
                />
              )}
            </FlyoutContent>
          </div>
        </Flyout>
      </>
    );
  } else {
    return <></>;
  }
};
