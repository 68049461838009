import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Link } from "@amzn/stencil-react-components/link";
import React, { useState } from "react";
import ICalendarLink from "react-icalendar-link";
import { HVH_COLORS } from "../../config/palette";
import { getChimeLinkForVirtualNHE } from "../../helpers/utils";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { StatusEnum } from "../../utility/enums/common";
import { displayVenueTime } from "../../utility/time-processor";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { FlyOutRTWAppointmentStatusCard, bgcUseStatusText } from "../utilities/FlyOutStatusCard";
import "./styles.scss";

interface RTWAppointmentProps {
  status: StatusEnum | NHEAppointmentStatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const RTWAppointmentScheduleStatus = ({ status, shouldOverrideStatus }: RTWAppointmentProps) => {
  const bundle = useChecklistArb();

  const overrideStatus = shouldOverrideStatus ? shouldOverrideStatus[status] : status;

  const { rtwAppointmentDetails } = useAppSelector(getConfigData);
  const { isSalesforceApplication } = useAppSelector(getFeatureFlag);
  const { rtwAppointmentSelfServiceURL } = useUrlBuilder();

  const [isWithVirtualNHE] = useState<boolean>(!isSalesforceApplication ? false : true);

  const googlePlaceURL = "http://maps.google.com/?q=";

  const googleMapLink = (
    <Link fontSize={"T200"} href={googlePlaceURL + rtwAppointmentDetails?.displayReadyLocation} target={"_blank"}>
      {rtwAppointmentDetails?.displayReadyLocation}
    </Link>
  );

  const displayTimeInVenueTime = displayVenueTime(rtwAppointmentDetails as AppointmentInfo);

  const dateTimeTranslated = bundle.formatMessage("Checklist-AppointmentCard-Month-Day-Year", {
    dateParam: displayTimeInVenueTime,
  });

  const formatedTime = `${dateTimeTranslated} ${rtwAppointmentDetails?.displayReadyTimeSlot}`;

  const rtwDetailsMessageTitle = bundle.getMessage("Checklist-Flyout-RTW-Appointment-Details-Title");

  const rtwScheduledDetailsMessageTitle = bundle.getMessage("Checklist-Flyout-RTW-Appointment-Scheduled-Details-Title");

  const rtwCompletedDetailsMessageMessage = bundle.getMessage(
    "Checklist-Flyout-RTW-Appointment-Completed-General-Message"
  );

  const locationDetails =
    rtwAppointmentDetails && !rtwAppointmentDetails?.errorStatusCode && isWithVirtualNHE
      ? bundle.formatMessage("Checklist-PreHireDetails-Location-Virtual-NHE", {
          address: getChimeLinkForVirtualNHE(
            rtwAppointmentDetails?.displayReadyLocation || "",
            bundle.getMessage("Checklist-Common-Virtual")
          ),
        })
      : bundle.formatMessage("Checklist-PreHireDetails-Location", {
          address: googleMapLink,
        });

  const addToCalendar = bundle.formatMessage("Checklist-AppointmentCard-ButtonText3");

  const appointmentTitle = bundle.formatMessage("Checklist-Flyout-RTW-Appointment-Calendar-Title");

  const event = {
    startTime: rtwAppointmentDetails?.startTimestamp ?? "",
    endTime: rtwAppointmentDetails?.endTimestamp ?? "",
    title: appointmentTitle,
    location: rtwAppointmentDetails?.displayReadyLocation,
  };

  const addToCalendarKeyDown = (event: any) => {
    if (event.key === " " || event.code === "Space") {
      event.preventDefault();
      (document.querySelector(".add-to-calendar") as any).click();
    }
  };

  const rtwAppointmentDetailGeneralMessage = bundle.getMessage("Checklist-RTW-Appointment-Detail-GeneralMessage");

  if (overrideStatus == NHEAppointmentStatusEnum.COMPLETED) {
    return (
      <FlyOutRTWAppointmentStatusCard
        status={status}
        image={<IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />}
        descriptionText={bgcUseStatusText(status)}
        headingText={rtwDetailsMessageTitle}
        messageText={rtwCompletedDetailsMessageMessage}
      />
    );
  }

  if (overrideStatus == NHEAppointmentStatusEnum.RESERVED) {
    return (
      <FlyOutRTWAppointmentStatusCard
        status={status}
        image={<IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />}
        descriptionText={bgcUseStatusText(status)}
        headingText={rtwScheduledDetailsMessageTitle}
        messageText={rtwAppointmentDetailGeneralMessage}
        dateText={formatedTime}
        locationText={locationDetails}
        appointmentSelfServiceURL={rtwAppointmentSelfServiceURL}
        addToCalendarButton={
          <Button
            data-testid="add-to-calendar-button"
            variant={ButtonVariant.Primary}
            as={"Col"}
            size={ButtonSize.Small}
            onKeyDown={(event) => addToCalendarKeyDown(event)}
            tabIndex={-1}
          >
            <ICalendarLink
              key="calendar-button"
              filename={appointmentTitle}
              event={event}
              className={"add-to-calendar"}
            >
              {addToCalendar}
            </ICalendarLink>
          </Button>
        }
      />
    );
  }

  return (
    <FlyOutRTWAppointmentStatusCard
      status={overrideStatus}
      descriptionText={bgcUseStatusText(NHEAppointmentStatusEnum.UNKNOWN)}
      headingText={rtwDetailsMessageTitle}
      messageText={rtwAppointmentDetailGeneralMessage}
    />
  );
};
