import React, { useEffect, useState } from "react";
import { HIRED_SF_STEP_SUBSTEP } from "../../config/appConfigConstants";
import { Stage } from "../../helpers/get-settings";
import { isSalesforceApplication } from "../../helpers/url-params";
import { isEligibleForDrugTestAppointment, isEligibleToDisplayNhe } from "../../helpers/utils";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { getCountryForDomain } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";
import { ContingencyResponse } from "../screening-results/screeningResults";
import { AppointmentCardControlled } from "./card-controlled";
import { SF_APP_STATE } from "../../utility/constants/application-status";
import { I9Response } from "../../utility/types/common";
import { useGetApplicationId } from "../../hooks/apis/useGetApplicationId";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { useGetRTWAppointment } from "../../hooks/apis/useGetRTWAppointment";
import { useGetNHEAppointment } from "../../hooks/apis/useGetNHEAppointment";
import { useGetDTAppointment } from "../../hooks/apis/useGetDTAppointment";
import { APPOINTMENT_NEED_TO_SCHEDULE } from "../../utility/constants/common";

interface AppointmentCardUncontrolledProps {
  trackNHEDetailsClick: () => void;
  trackStartDateDetailsClick: () => void;
  cssURLWithoutScheduleId?: string;
  dtResponse?: ContingencyResponse;
  bgcResponse?: ContingencyResponse;
  i9Response?: I9Response;
  stage?: Stage;
}

export const AppointmentCardUncontrolled = ({
  cssURLWithoutScheduleId,
  trackNHEDetailsClick,
  trackStartDateDetailsClick,
  stage,
}: AppointmentCardUncontrolledProps) => {
  const country = getCountryForDomain();
  const { sdaData, caasI9Status, caasRTWStatus } = useAppSelector(getConfigData);

  const applicationId = useGetApplicationId();
  const rtwInfo = useGetRTWAppointment();
  const nheInfo = useGetNHEAppointment();
  const dtInfo = useGetDTAppointment();
  const jcDetails = useGetJobDetails();
  const applicationManageData = useGetApplicationManageData();
  const candidateData = useGetCandidateData();

  const [displayNHE, setDisplayNHE] = useState<boolean>(false);
  const [displayDT, setDisplayDT] = useState<boolean>(false);
  const [isDTNeedToBeScheduled, setIsDTNeedToBeScheduled] = useState<boolean>(false);
  const [displayScreening, setDisplayScreening] = useState<boolean>(false);
  const [scheduleIdFound, setScheduleIdFound] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (sdaData && applicationManageData && candidateData && jcDetails) {
      const requestNheAndSda = async () => {
        setLoading(true);

        if (!candidateData?.candidateId || !candidateData?.candidateGlobalId) {
          dispatchOnDev(() => console.error("No candidate id"));

          setError(true);
        }

        try {
          const applicationManageDetails = applicationManageData;

          if (!isSalesforceApplication(applicationId)) {
            if (
              rtwInfo?.errorStatusCode === 404 &&
              applicationManageDetails?.applicationState === "CONTINGENCIES_PENDING"
            ) {
              dispatchOnDev(() => console.log("display screening is set to true"));
              setDisplayScreening(true);
            }

            if (jcDetails?.error) {
              dispatchOnDev(() => console.error("jcDetail Error", jcDetails?.error));

              setError(true);
            } else {
              if (jcDetails?.isScheduleReleased) {
                dispatchOnDev(() => console.log("schedule id not found"));
                setScheduleIdFound(false);
              }
            }

            if (sdaData?.errorCode) {
              dispatchOnDev(() => console.error("sdaDetails Error", sdaData.errorCode));

              setError(true);
            } else {
              if (nheInfo && rtwInfo && (!nheInfo.errorStatusCode || !rtwInfo.errorStatusCode)) {
                const showNHE = isEligibleToDisplayNhe({
                  nheDetails: nheInfo,
                  rtwDetails: rtwInfo,
                  applicationManageDetails,
                  country: getCountryForDomain(),
                });
                dispatchOnDev(() => console.log(`set display nhe to ${showNHE}`));
                setDisplayNHE(showNHE);
              }

              if (dtInfo) {
                const showDT = isEligibleForDrugTestAppointment({
                  dtDetails: dtInfo,
                  applicationManageDetails,
                  applicationId,
                });
                dispatchOnDev(() => console.log(`set display drugTest to ${showDT}`));
                setDisplayDT(showDT);

                const dtNeedSchedule = dtInfo?.errorCode === APPOINTMENT_NEED_TO_SCHEDULE;
                dispatchOnDev(() => console.log(`set isDTScheduled to ${dtNeedSchedule}`));
                setIsDTNeedToBeScheduled(dtNeedSchedule);
              }

              setError(false);
            }
          } else {
            if (!applicationManageDetails?.applicationStepSubStep) {
              dispatchOnDev(() => console.error("No application step and sub-step."));

              setError(true);
            } else {
              dispatchOnDev(() => console.log("set display nhe and dt to false"));
              setDisplayNHE(false);
              setDisplayDT(false);
              if (!HIRED_SF_STEP_SUBSTEP.includes(applicationManageDetails?.applicationStepSubStep as SF_APP_STATE)) {
                //if not ready for hire or hire confirmed, set header to display screening
                dispatchOnDev(() => console.log("set display screening to true"));
                setDisplayScreening(true);
              }
              setScheduleIdFound(false);
            }
          }
        } catch (e) {
          dispatchOnDev(() => console.error("card-uncontrolled error", e));

          setError(true);
        }
        setLoading(false);
      };

      requestNheAndSda();
    }
  }, [
    sdaData,
    applicationManageData,
    candidateData,
    jcDetails,
    nheInfo,
    rtwInfo,
    dtInfo,
    applicationId,
    country,
    caasRTWStatus,
    caasI9Status,
  ]);

  return (
    <AppointmentCardControlled
      onViewNHEDetailsClick={trackNHEDetailsClick}
      onViewStartDateDetailsClick={trackStartDateDetailsClick}
      displayNHE={displayNHE}
      displayDT={displayDT}
      isDTNeedToBeScheduled={isDTNeedToBeScheduled}
      displayScreening={displayScreening}
      scheduleIdFound={scheduleIdFound}
      cssURLWithoutScheduleId={cssURLWithoutScheduleId}
      loading={loading}
      error={error}
      stage={stage}
    />
  );
};
